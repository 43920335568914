/** 新闻动态 */
<template>
  <div :class="['policy', 'content', {'mt-30':$isPc}]">
    <section v-if="!$isPc" class="select-con justify-center align-center" :class="{'flex-wrap':!$isPc}">
      <Search backColor="#fff" @searchBtn="searchBtn" :class="{'pa-0':!$isPc}" />
    </section>
    <LoadMore :onLoadMore="onLoadMore" :loading="loading" :finished="finished">
      <List :data="policyData" :loading="loading" :total="pageTotal" @page="changePage">
        <template v-slot="{item}">
          <router-link :to="{ path: '/article_details', query: { id: item.id, type: 'news' } }" class="flex">
            <!-- <el-image src="" class="item-img border-r4 flex-1"> </el-image> -->
            <div :class="['item-info', {'flex-1':$isPc}, 'flex-column', $isPc?'justify-between':'justify-around']">
              <div class="justify-between">
                <h4 class="item-title text-ellipsis-lines">
                  {{item.title}}
                </h4>
                <el-tag v-if="item.is_top==2" type="danger" effect="dark">
                  置顶
                </el-tag>
              </div>
              <div v-if="$isPc" class="item-info-text text-ellipsis-lines">
                {{$settleHtml(item.article_content)}}
              </div>
              <p class="item-info-desc">
                <span style="float:right">
                  发布时间：{{item.created_at|filterTime}}
                </span>
              </p>
            </div>
          </router-link>
        </template>
      </List>
    </LoadMore>
  </div>
</template>
<script>
import { getArticle } from '@/api/article'
import LoadMore from '@/components/LoadMore.vue'
import List from '@/components/List.vue'
import Search from '@/components/Search.vue'
export default {
  name: 'Policy',
  components: {
    LoadMore,
    List,
    Search
  },
  data () {
    return {
      filtersData: {
        article_type: '新闻动态',
        keywords: '',
        page: 1
      },
      pageTotal: 0,
      policyData: [],
      loading: false,//正在加载
      finished: false, // 加载完成
    }
  },
  async mounted () {
    this.onLoadMore()
  },
  methods: {
    async getPolicyData (filtersData) {
      try {
        let { data, total, code } = await getArticle(filtersData)
        if (code == 200) {
          this.policyData = this.$isPc ? data : [...this.policyData, ...data]
          this.pageTotal = total
          this.isReset = false
        }
      } catch (error) { }
      this.loading = false;
    },
    // 搜索
    searchBtn (val) {
      this.filtersData.keywords = val
      this.isReset = true
      this.onLoadMore()
    },
    // 整合查询数据
    async setFiltersData () {
      if (this.isReset) {
        this.pageTotal = 0
        this.policyData = []
        this.filtersData.page = 1
        this.resetPage = !this.resetPage
        this.loading = true
        this.finished = false
      }
      if (!this.filtersData.keywords) {
        delete this.filtersData.keywords
      }
      let newFiltersData = JSON.parse(JSON.stringify(this.filtersData));

      await this.getPolicyData(newFiltersData)
    },
    // 切换分页
    changePage (page) {
      this.filtersData.page = page
      this.onLoadMore()
    },
    // 上拉加载
    async onLoadMore (done) {
      this.loading = true
      await this.setFiltersData()
      done && done();
      if (this.policyData.length >= this.pageTotal) {
        this.finished = true
        return
      }
      this.filtersData.page += 1
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/.select-con {
  padding: 20px;
  background-color: #eff3fa;
}
/deep/.list {
  .item {
    height: 145px;
  }
}
.el-tag--danger {
  height: 20px;
  line-height: 18px;
  border-radius: 2px;
}
@media (max-width: 767px) {
  /deep/.list {
    .item {
      height: 80px;
    }
  }
}
</style>